<template>
  <div>
    <div class="company-wrap" v-if="!isPc">
      <title-module :titleInfo="titleInfo" />
      <div class="content" v-for="item in listData">
        <div class="img">
          <img :src="listData_pic" alt=""/>
        </div>
        <div class="text">
          <div v-html="item.content">
          </div>
        </div>
      </div>
    </div>
    <div class="company-wrap-pc .wd-1200" v-else>
      <div class="text-content">
        <div class="text-wrap">
          <div class="title">
            <span class="title-text">
              <span class="color-red">公司</span>
              <span class="color-black">简介</span>
            </span>
            <span class="text-eg">COMPANY PROFILE</span>
          </div>
          <div class="content" v-for="item in listData">
            <div class="text">
              <div v-html="item.content">
              </div>
            </div>
          </div>
          <div class="button">
            <moreButton @seeMore="seeMore" style="float: right;"/>
          </div>
        </div>
        <!-- <more-button @seeMore="toBrief" /> -->
      </div>
      <div class="image">
        <img :src="listData_pic" alt=""/>

      </div>
    </div>
  </div>
</template>

<script>
import { getFeatureContent } from "@/api/index.js";
import titleModule from "@/components/title";
import moreButton from "@/components/seeMore";
export default {
  props: ["isPc"],
  components: {
    titleModule,
    moreButton
  },
  data() {
    return {
      listData:'',
      listData_pic:'',
      titleInfo: {
        titleRed: "公司",
        titleWhite: "简介",
        remark: "COMPANY PROFILE",
        noBold: true,
       
      } 
    };
  },

  methods: {
    seeMore() {
      this.$router.push("/about");
    },
    goResult(){
      this.$router.push('../../antiFake/result')
    },
    async getInfo(){
    const params = {
        pageNumber: 1,
        pageSize: 8,
        sort: "time",
        order: "asc",
        navigationId: "1305772076707221504"
      };
      try {
        const { result } = await getFeatureContent(params);
        const data = result ? result.content : [];
        this.listData = data  
        console.log(this.listData)
        this.listData_pic = data[0].pictureList[0].fileUrl
      } catch (error) {
        console.log(error);
        this.$message.error("获取内容失败！");
      }
   }
  },

  created() {
    this.getInfo()
  }
};
</script>

<style lang="less" scoped>
.company-wrap {
  padding: 48px;
  background-color: #ffffff;
  box-shadow: 0px 3px 10px 0px rgba(191, 206, 222, 0.2);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .content {
    width: 100%;
    .img {
      margin: 40px 0 35px;
      display: flex;
      justify-content: space-between;
      img {
        width: 1030px;
        height: 640px;
      }
    }
    .text {
      text-align: left;
      p {
        font-size: 30px;
        line-height: 60px;
        letter-spacing: 0px;
        color: #1d1b1b;
        text-indent: 2em;
      }
    }
  }
}
</style>
