<template>
  <section
    class="annunciate-container"
    :class="!isPc ? 'annunciate-container-phone' : ''"
  >
    <div class="title">
      <div class="text">最新公告</div>
      <div class="icon"></div>
    </div>
    <div class="content">
      <ul :class="{ anim: animate == true }">
        <li
          v-for="item in annunciateData"
          :key="item.id"
          @click="toselected(item)"
        >
          <span class="icon"></span>
          <span class="text-title">{{ item.title }}</span>
          <span class="text-time"> {{ item.time }}</span>
        </li>
      </ul>
    </div>
    <more-button @seeMore="toAnnunciate" v-if="isPc" />
  </section>
</template>

<script>
import moreButton from "./seeMore";
export default {
  props: {
    annunciateData: {
      type: Array,
      default: () => {
        return [];
      }
    },
    isPc: {
      type: Boolean
    }
  },
  mounted() {
    this.timer = setInterval(this.scroll, 2000);
  },
  components: {
    moreButton
  },
  data() {
    return {
      animate: true,
      timer: null,
      timer2: null
    };
  },
  methods: {
    scroll() {
      this.animate = true; // 过渡动画状态
      if (this.timer2) clearTimeout(this.timer2);
      this.timer2 = setTimeout(() => {
        this.annunciateData.push(this.annunciateData[0]); // 将数组的第一个元素添加到数组
        this.annunciateData.shift(); //删除数组的第一个元素
        this.animate = false; // margin-top 为0 的时候取消过渡动画，实现无缝滚动
      }, 500);
    },

    toAnnunciate() {
      this.$router.push("/information");
    },
    toselected(item) {
      this.$router.push({
        path: "/information",
        query: { id: item.id, navigationId: item.navigationId }
      });
    }
  },
  beforeDestroy() {
    clearInterval(clearInterval(this.timer));
  }
};
</script>

<style lang="less" scoped>
.annunciate-container-phone {
  position: static;
  width: 100%;
  height: 171px;
  background-color: #ffffff;
  box-shadow: 0px 3px 10px 0px rgba(191, 206, 222, 0.2);
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title,
  .content {
    height: 100%;
    padding: 31px 0 31px 46px;
    cursor: pointer;
    overflow: hidden;
  }
  .title {
    display: flex;
    .text {
      width: 110px;
      font-size: 48px;
      font-weight: bold;
      color: rgba(198, 29, 36, 1);
      line-height: 60px;
      letter-spacing: 5px;
    }
    .icon {
      width: 0;
      margin: 20px 46px 30px 25px;
      height: 70px;
      border: 1px solid rgba(230, 230, 230, 1);
      transform: rotate(20deg);
    }
  }
  .content {
    flex: 1;
    padding: 0;
    height: 98px;
    overflow: hidden;
    // margin-top: -10px;
    ul {
    }
    li {
      font-size: 30px;
      color: #1d1b1b;
      text-align: left;
      display: flex;
      align-items: center;
      cursor: pointer;
      line-height: 48px;
      .icon {
        width: 16px;
        height: 16px;
        background-color: #c10320;
        border: 1px solid#c10320;
        border-radius: 50%;
        margin-right: 21px;
      }
      .text-title {
        margin-right: 60px;
        max-width: 500px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .text-time {
        color: #ccc;
      }
    }
  }
  .anim {
    transition: all linear 0.3s;
    margin-top: -49px;
  }
}
</style>
