<template>
  <div class="map-wrap">
    <title-module :titleInfo="titleInfo" />
    <div class="content-wrap">
      <div class="address" @click="checkAdress">
        <div class="text">
          <span>{{ activeDress.address }}</span
          ><br />
          <div class="address-info">
            <van-icon name="location" />
            <span v-html="activeDress.detail"></span>
          </div>
        </div>
        <div class="icon" :class="{ 'icon-rotate': listShow }">
          <img src="../../../assets/img/map-slect.png" alt="" />
        </div>
      </div>
      <div class="map-view">
        <div id="container" class="map-content"></div>
      </div>
    </div>
    <div class="address-list" :class="{ 'list-show': listShow }">
      <ul>
        <li
          v-for="item in adressInfo"
          :key="item.active"
          @click="selectAdress(item)"
          :class="{ actived: activeDress.active === item.active }"
        >
          <div class="text">
            <span>{{ item.address }}</span
            ><br />
            <div class="address-info">
              <van-icon name="location" />
              <span v-html="item.address"></span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import titleModule from "../../../components/title";
import AMap from "AMap";
export default {
  components: {
    titleModule
  },
  data() {
    return {
      titleInfo: {
        titleRed: "在线",
        titleWhite: "地图",
        remark: "ONLINE MAP"
      },
      map: "",
      listShow: false,
      adressInfo: [
        {
          address: "香港喜尔美（集团）投资管理有限公司",
          detail: "&nbsp;&nbsp香港新界葵涌葵昌路26-38号豪华工业大厦23楼B07室",
          active: 1
        },
        {
          address: "江西喜尔美喜庆用品连锁有限公司",
          detail: "&nbsp;&nbsp江西省宜春市万载县马步乡喜尔美烟花爆竹物流基地",
          active: 2
        },
        {
          address: "安徽儒彧电子信息科技有限公司",
          detail: "&nbsp;&nbsp安徽省合肥市高新区创新大道与皖水路交叉口",
          active: 3
        }
      ],
      activeDress: {}
    };
  },
  computed: {
    destInfo() {
      let str = "";
      switch (this.activeDress.active) {
        case 1:
          str = [114.138204, 22.362083]; //香港喜尔美（集团）投资管理有限公司
          break;
        case 2:
          str = [114.465828, 28.042409]; //江西喜尔美喜庆用品连锁有限公司
          break;
        case 3:
          str = [117.133332, 31.847716]; //安徽儒彧电子信息科技有限公司
          break;
      }
      return str;
    }
  },
  mounted() {
    const that = this;
    setTimeout(() => {
      that.selectAdress(that.adressInfo[0]);
    }, 1000);
  },
  methods: {
    checkAdress() {
      this.listShow = !this.listShow;
    },
    selectAdress(val) {
      this.activeDress = val;
      this.listShow = false;
      this.MapInit();
    },
    // 初始化地图 定位
    // 初始化
    MapInit() {
      let _this = this;
      this.map = new AMap.Map("container", {
        center: _this.destInfo,
        resizeEnable: true,
        zoom: 12
      });
      // 创建一个 Marker 实例：
      var marker = new AMap.Marker({
        position: new AMap.LngLat(_this.destInfo[0], _this.destInfo[1]) // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        // icon: '//vdata.amap.com/icons/b18/1/2.png', // 添加 Icon 图标 URL
        // title: '北京'
      });
      // 将创建的点标记添加到已有的地图实例：
      this.map.add(marker);
    }
  },
  beforeDestroy() {
    this.map && this.map.destroy();
  }
};
</script>

<style lang="less" scoped>
.map-wrap {
  padding: 48px 48px 60px;
  background-color: #ffffff;
  box-shadow: 0px 3px 10px 0px rgba(191, 206, 222, 0.2);
  border-radius: 20px;
  position: relative;
  .content-wrap {
    width: 953px;
    margin: 0 auto;
    margin-top: 30px;
    text-align: left;
    .address {
      width: 953px;
      height: 148px;
      border-radius: 10px;
      border: solid 1px #e5e5e5;
      padding: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .text {
        font-size: 36px;
        font-weight: bold;
        line-height: 30px;
        color: #333333;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .address-info {
          font-size: 28px;
          color: #666666;
          display: flex;
          align-items: center;
        }
      }
      .icon {
        width: 29px;
        height: 19px;
        transition: all linear 0.5s;
      }
      .icon-rotate.icon {
        transform: rotate(180deg);
        // transform-origin: center;
      }
    }
    .map-content {
      width: 100%;
      height: 390px;
      margin-top: 30px;
    }
  }

  .address-list {
    position: absolute;
    text-align: left;
    top: 308px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    max-height: 0;
    overflow: hidden;
    background-color: #fff;
    border-radius: 10px;
    border: solid 1px #e5e5e5;
    border-top: none;
    border-bottom: none;
    transition: all linear 0.5s;
    ul {
      li {
        width: 953px;
        height: 148px;
        border-radius: 10px;
        padding: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e5e5e5;
        transition: all linear 0.1s;
        .text {
          font-size: 36px;
          font-weight: bold;
          line-height: 30px;
          color: #333333;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .address-info {
            font-size: 28px;
            color: #666666;
            display: flex;
            align-items: center;
          }
        }
        .icon {
          width: 29px;
          height: 19px;
        }
      }
      li.actived {
        background-color: #c90f2c;
        .text,
        .address-info {
          color: #fff;
        }
      }
    }
    // border: none;
  }

  .address-list.list-show {
    max-height: 1000px;
    border-top: none;
  }
}
</style>
