<template>
  <section class="map-container">
    <div class="map-content type-area">
      <!-- <div class="title" v-if="!type">
        <span>在线地图</span>
      </div> -->
      <titleModule class="title" :titleInfo="titleInfo" :isPc="isPc" />
      <div class="map-view" :class="{ 'us-map': type }">
        <div class="map-nav">
          <ul>
            <!-- <li @click="selectAdress(1)" :class="{ active: active === 1 }">
              <div class="compny">香港喜尔美（集团）投资管理有限公司</div>
              <div class="compny-adress">
                <van-icon name="location-o" />
                香港新界葵涌葵昌路26-38号豪华工业大厦23楼B07室
              </div>
            </li> -->
            <li @click="selectAdress(2)" :class="{ active: active === 2 }">
              <div class="compny">江西喜尔美喜庆用品连锁有限公司</div>
              <div class="compny-adress">
                <van-icon name="location-o" />
                江西省宜春市万载县马步乡喜尔美烟花爆竹物流基地
              </div>
            </li>
            <li @click="selectAdress(3)" :class="{ active: active === 3 }">
              <div class="compny">安徽儒彧电子信息科技有限公司</div>
              <div class="compny-adress">
                <van-icon name="location-o" />
                安徽省合肥市高新区创新大道与皖水路交叉口
              </div>
            </li>
          </ul>
        </div>
        <div class="map">
          <div id="container" class="map-content"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script type="text/ecmascript-6">
import AMap from "AMap";
import titleModule from "@/components/title";
export default {
  props: ["type", "isPc"],
  components: {
    titleModule
  },
  data() {
    return {
      active: 1,
      map: "",
      titleInfo: {
        titleRed: "在线", 
        titleWhite: "地图",
        remark: "ONLINE MAP"
      }
    };
  },
   computed: {
    destInfo() {
      let str = "";
      switch (this.active) {
        case 1:
          str = [114.1327, 22.36348]; //香港喜尔美（集团）投资管理有限公司
          break;
        case 2:
          str = [114.45853,28.05764]; //江西喜尔美喜庆用品连锁有限公司
          break;
        case 3:
          str = [117.1277,31.849548]; //安徽儒彧电子信息科技有限公司
          break;
      }
      return str;
    }
  },
  mounted() {
    let that = this;
     setTimeout(() => {
      that.selectAdress(2);
    }, 500);
  },
  methods: {
    selectAdress(val) {
      this.active = val;
      document.querySelector('.map').innerHTML = ''
      this.MapInit();
    },
    // 初始化地图 定位
    // 初始化
    MapInit() {
      var map_box = document.querySelector('.map')
      var newDiv = document.createElement("div");
      newDiv.id = 'container'
      newDiv.className = 'map-content'
      newDiv.style.height = '100%'
      map_box.appendChild(newDiv)
      let that = this
      var T = window.T
      var map;
      map = new T.Map('container');
      map.centerAndZoom(new T.LngLat(that.destInfo[0],that.destInfo[1]), 16);
      var marker = new T.Marker(new T.LngLat(that.destInfo[0],that.destInfo[1]));
      map.checkResize()
      map.disableInertia()
      map.addOverLay(marker);
    }
  },
  beforeDestroy() {
    this.map && this.map.destroy();
  }
};
</script>
<style lang="less" scoped>
@import "../../../styles/mapPc.less";
::v-deep .tdt-bottom{
  display: none;
}
</style>
