<template>
  <div class="title" :class="{ 'module-title-pc': isPc }">
    <span class="left-hr"></span>
    <span class="text" :class="{ 'no-bold': titleInfo.noBold }"
      >{{ titleInfo.titleRed | strFilter
      }}<i
        class="jianjie"
        :class="{ 'text-f': titleInfo.titleWhite === '电子花炮' }"
        >{{ titleInfo.titleWhite | strFilter }}</i
      ></span
    >
    <span class="right-hr"></span>
    <span class="text-remark">{{ titleInfo.remark | strFilter }}</span>
  </div>
</template>

<script>
export default {
  props: ["titleInfo", "isPc"],
  data() {
    return {};
  },
  filters: {
    strFilter(val) {
      return val || "";
    }
  },

  methods: {},

  created() {}
};
</script>

<style lang="less" scoped>
.title {
  margin-top: 5px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  .left-hr,
  .right-hr {
    width: 240px;
    height: 2px;
    border-radius: 1px;
  }
  .left-hr {
    background-image: linear-gradient(to left, #e3e3e3, #fff);
    margin-right: 40px;
  }
  .right-hr {
    background-image: linear-gradient(to right, #e3e3e3, #fff);
    margin-left: 35px;
  }
  span.text {
    position: relative;
    font-size: 48px;
    // font-weight: bold;
    line-height: 34px;
    letter-spacing: 5px;
    color: #d4101e;
    .jianjie {
      color: #000000;
    }
  }
  .text-remark {
    font-size: 24px;
    width: 100%;
    line-height: 26px;
    letter-spacing: 0px;
    color: #e6e6e6;
    display: inline-block;
    margin-top: 15px;
  }
}
</style>
